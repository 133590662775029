<template>
  <div>
    <b-card title="검색 필터">
      <b-row class="mb-1">
        <b-col cols="4">
          <b-form-group
            label="시작 시간"
            label-cols="2"
          >
            <flat-pickr
              v-model="startAt"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                maxDate: 'today',
                minDate: '2019-04'
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="끝 시점"
            label-cols="2"
          >
            <flat-pickr
              v-model="endAt"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                maxDate: 'today'
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="1"
          v-text="'알람타입'"
        />
        <b-col>
          <b-form-radio-group
            v-model="selectedMsgType"
            :options="msgTypeOptions"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          align-self="center"
          cols="1"
          v-text="'전화번호'"
        />
        <b-col
          cols="3"
          class="pl-0"
        >
          <b-input-group>
            <b-form-input
              v-model="phoneNumber"
              placeholder="전화번호 전체(-빼고)"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-button
            variant="primary"
            class="float-right"
            @click="searchNotificationList"
            v-text="'검색'"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-table
      :items="searchResult"
      :fields="tableColumnNames"
      show-empty
      empty-text="검색 결과가 없습니다."
      responsive
      small
    >
      <template #cell(member)="data">
        <b-link
          v-if="data.item.member_type === 'INF'"
          :to="{ name: 'member-inf-detail', params: { idx: data.item.member_idx }}"
        >
          ({{ data.item.member_type }}) {{ data.item.member_idx }}
        </b-link>
        <b-link
          v-if="data.item.member_type === 'ADV'"
          :to="{ name: 'member-adv-view', params: { idx: data.item.member_idx }}"
        >
          ({{ data.item.member_type }}) {{ data.item.member_idx }}
        </b-link>
      </template>

      <template #cell(message)="data">
        <div class="table-content__message">
          {{ data.value }}
        </div>
      </template>

      <template #cell(show-full-text)="data">
        <a
          v-b-modal.show-full-text-modal
          style="color:#258BFF; cursor:pointer;"
          @click="showFullText(data.item)"
          v-text="'내용 전체보기'"
        />
      </template>
    </b-table>
    <b-pagination
      v-if="totalCount > 0"
      v-model="currentPage"
      :total-rows="totalCount"
      :per-page="size"
    />
    <b-modal
      id="show-full-text-modal"
    >
      {{ fullText }}
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormRadioGroup,
  BTable,
  BPagination,
  BLink,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios'
import router from '@/router'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BFormRadioGroup,
    BTable,
    BPagination,
    BLink,

    flatPickr,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const selectedMsgType = ref(router.history.current.query.msgType ?? 'PUSH')
    const msgTypeOptions = [
      { text: '푸시', value: 'PUSH' },
      { text: '이메일', value: 'EMAIL' },
    ]
    const startAt = ref('')
    const endAt = ref('')
    if (router.history.current.query.start_at) {
      startAt.value = moment(router.history.current.query.start_at).format('YYYY-MM-DD HH:mm')
    } else {
      startAt.value = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm')
    }
    if (router.history.current.query.end_at) {
      endAt.value = moment(router.history.current.query.end_at).format('YYYY-MM-DD HH:mm')
    } else {
      endAt.value = moment().format('YYYY-MM-DD HH:mm')
    }

    const phoneNumber = ref('')
    const currentPage = ref(1)
    const size = 20
    const searchResult = ref([])
    const totalCount = ref(0)
    const tableColumnNames = [
      { key: 'idx' },
      { key: 'member', label: '회원번호' },
      { key: 'sending_state', label: '전송결과', formatter: val => (val ? '성공' : '실패') },
      { key: 'is_read', label: '읽음 여부', formatter: val => (val ? '읽음' : '안 읽음') },
      { key: 'message', label: '메시지' },
      { key: 'created_at', label: '전송일시', formatter: val => moment(val).format('YYYY-MM-DD HH:mm') },
      { key: 'show-full-text', label: '내용 전체보기' },
    ]

    const searchNotificationList = async () => {
      if (startAt.value > endAt.value) {
        alert('시작일자가 끝 일자보다 클 수는 없습니다')
        return
      }
      const queryParams = {
        msg_type: selectedMsgType.value,
        start_at: moment(startAt.value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm'),
        end_at: moment(endAt.value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm'),
        phone_number: phoneNumber.value,
        page: currentPage.value,
        size,
      }

      try {
        const result = await axios.get(
          `${process.env.VUE_APP_BACKEND_SERVER}/manage/notifications/`,
          { params: queryParams },
        )
        searchResult.value = result.data.data
        totalCount.value = result.data.page_info.total_count
      } catch (e) {
        console.log(e)
      } finally {
        router.replace({
          query: {
            msg_type: selectedMsgType.value,
            start_at: startAt.value,
            end_at: endAt.value,
            phone_number: phoneNumber.value,
            page: currentPage.value,
            size,
          },
        }).catch(() => {})
      }
    }
    searchNotificationList()

    // modal
    const fullText = ref('')
    const showFullText = item => {
      fullText.value = item.message
    }

    watch(currentPage, () => {
      searchNotificationList()
    })

    return {
      selectedMsgType,
      msgTypeOptions,
      startAt,
      endAt,
      phoneNumber,

      size,
      currentPage,
      searchResult,
      totalCount,
      tableColumnNames,

      searchNotificationList,

      // modal
      fullText,
      showFullText,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.table-content__message {
  overflow: hidden;
  white-space: nowrap;
  width: 600px;
  text-overflow: ellipsis;
}
</style>
